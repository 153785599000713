@import "../../scss/main.scss";

#mvp {
  background-color: $gid-white;
  padding: 224px 0 0;

  @include breakpoint(tablet) {
  }
  @include breakpoint(mobile) {
    padding: 160px 0 0;
  }
  .hero {
    grid-column: 4 / 10;
    text-align: center;

    @include breakpoint(tablet) {
      grid-column: 3 / 7;
    }
    @include breakpoint(mobile) {
      grid-column: 1 / 5;
    }
    h1 {
      @include breakpoint(tablet) {
      }
      @include breakpoint(mobile) {
      }
    }
    h3 {
      @include breakpoint(mobile) {
      }
    }
    .hero-image {
      margin-top: 80px;

      img {
        width: 100%;
      }
      @include breakpoint(tablet) {
        grid-column: 1 / 7;
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
        text-align: center;
      }
    }
    .center-arrow {
      display: none;

      @include breakpoint(mobile) {
        display: block;
      }
    }
    aside {
      position: absolute;
      top: 25%;

      @include breakpoint(mobile) {
        display: none;
      }
      &.left-arrow {
        left: 0;
        transform: translateX(-176px) rotate(90deg);

        img {
          padding-left: 20px;
        }
      }
      &.right-arrow {
        right: 0;
        transform: translateX(177px) rotate(-90deg);

        img {
          padding-left: 20px;
          transform: rotate(180deg) rotateX(180deg);
        }
      }
    }
  }
  .chart {
    display: grid;
    gap: 20px;
    grid-column: 1 / 13;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 180px;
    text-align: center;

    @include breakpoint(tablet) {
      grid-column: 1 / 9;
      grid-template-columns: repeat(8, 1fr);
    }
    @include breakpoint(mobile) {
      grid-column: 1 / 5;
      grid-template-columns: repeat(4, 1fr);
    }
    .title {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 27px;
      grid-column: 3 / 11;

      h2 {
        font-size: 3rem;
        font-weight: normal;
        line-height: 3rem;

        @include breakpoint(mobile) {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
      }
      p {
        font-size: 1.25rem;

        @include breakpoint(mobile) {
          font-size: 1rem;
        }
      }
      @include breakpoint(tablet) {
        grid-column: 2 / 8;
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
      }
    }
    .chart-container {
      display: grid;
      gap: 20px;
      grid-column: 1 / 13;
      grid-template-columns: repeat(12, 1fr);
      margin-top: 120px;

      img {
        grid-column: 1 / 13;
        width: 100%;
      }
      @include breakpoint(tablet) {
        grid-column: 1 / 9;
        grid-template-columns: repeat(8, 1fr);
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
        grid-template-columns: repeat(4, 1fr);
      }
    }
    .footer {
      display: grid;
      gap: 20px;
      grid-column: 1 / 13;
      grid-template-columns: repeat(12, 1fr);

      @include breakpoint(tablet) {
        grid-column: 1 / 9;
        grid-template-columns: repeat(8, 1fr);
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
        grid-template-columns: repeat(4, 1fr);
      }
      .chart-description {
        display: grid;
        column-gap: 20px;
        row-gap: 80px;
        grid-column: 1 / 13;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @include breakpoint(tablet) {
          grid-column: 1 / 9;
          grid-template-columns: 1fr 1fr;
        }
        @include breakpoint(mobile) {
          grid-column: 1 / 5;
          grid-template-columns: 1fr;
        }
        .describe-container {
          text-align: start;
          margin-right: 80px;
          grid-column: 1fr;
          @include breakpoint(tablet) {
            margin-right: 0;
          }
          @include breakpoint(mobile) {
          }
          h1 {
            font-size: 32px;
          }
          p {
            font-size: 14px;
          }
        }
      }
      .btn-container {
        margin-top: 140px;
        grid-column: 5 / 9;
        @include breakpoint(tablet) {
          grid-column: 3 / 7;
        }
        @include breakpoint(mobile) {
          grid-column: 1 / 5;
        }
        .btn {
          width: 100%;
        }
      }
    }
  }
  .features-container {
    display: grid;
    gap: 20px;
    grid-column: 1 / 13;
    grid-template-columns: repeat(12, 1fr);
    padding-top: 180px;
    @include breakpoint(tablet) {
      grid-column: 1 / 9;
      grid-template-columns: repeat(8, 1fr);
    }
    @include breakpoint(mobile) {
      grid-column: 1 / 5;
      grid-template-columns: repeat(4, 1fr);
    }
    .feature {
    }
  }

  @include breakpoint(tablet) {
    grid-column: 1 / 9;
  }
  @include breakpoint(mobile) {
    grid-column: 1 / 5;
  }
}
