.box {
  background-color: $gid-white;
  box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.1), -6px -6px 12px 0px #fff;
  width: 100%;

  -webkit-box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.1),
    -6px -6px 12px 0px #fff;
  @include breakpoint(tablet) {
    background-color: unset;
    box-shadow: unset;
    grid-column: unset;
    width: unset;
  }
}

#form {
  .box {
    background-color: $gid-white;
    box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.1), -6px -6px 12px 0px #fff;
    width: 100%;
    border-top-right-radius: 32px;
    border-bottom-left-radius: 32px;

    -webkit-box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.1),
      -6px -6px 12px 0px #fff;
  }
}
