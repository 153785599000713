@import "../../../scss/main.scss";

#mvp-hero {
  background-color: $gid-white;
  padding: 224px 0 0;
  grid-column: 1 / 13;
  @include breakpoint(tablet) {
    grid-column: 1 / 9;
  }
  @include breakpoint(mobile) {
    grid-column: 1 / 5;
    padding: 160px 0 0;
  }
  .hero {
    grid-column: 4 / 10;
    text-align: center;

    @include breakpoint(tablet) {
      grid-column: 3 / 7;
    }
    @include breakpoint(mobile) {
      grid-column: 1 / 5;
    }
    h1 {
      @include breakpoint(tablet) {
      }
      @include breakpoint(mobile) {
      }
    }
    h3 {
      @include breakpoint(mobile) {
      }
    }
    .hero-image {
      margin-top: 80px;

      img {
        width: 100%;
      }
      @include breakpoint(tablet) {
        grid-column: 1 / 7;
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
        text-align: center;
      }
    }
    .center-arrow {
      display: none;

      @include breakpoint(mobile) {
        display: block;
      }
    }
    aside {
      position: absolute;
      top: 40%;
      @include breakpoint(mobile) {
        display: none;
      }
      &.left-arrow {
        left: 0;
        transform: translateX(-176px) rotate(90deg);

        img {
          padding-left: 20px;
        }
      }
      &.right-arrow {
        right: 0;
        transform: translateX(177px) rotate(-90deg);

        img {
          padding-left: 20px;
          transform: rotate(180deg) rotateX(180deg);
        }
      }
    }
  }
}
