@import "../../scss/main.scss";

#policy,
#statute {
  margin: 222px 0;
  @include breakpoint(tablet) {
    margin: 160px 0;
  }
  main {
    margin: 0 224px;
    @include breakpoint(tablet) {
      margin: 60px 70px;
    }
    @include breakpoint(mobile) {
      overflow: hidden;
      margin: 30px 30px;
    }

    h1 {
      margin-bottom: 100px;
    }
    .content {
      article {
        margin-bottom: 80px;
        h3 {
          margin-bottom: 40px;
          text-transform: uppercase;
        }
        p {
          line-height: 28px;
          &.subtitle {
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }
    }
  }
}
