@import "../../scss/main.scss";

.inputBox {
  position: relative;
  min-width: 290px;
  width: 35%;
  margin: 0 0 42px 0;
  @include breakpoint(tablet) {
    margin: 0 0 42px 0;
  }
  @include breakpoint(mobile) {
    width: 100%;
  }
  & input {
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
    border: 0 none;
    outline: none;
    z-index: 1;
    &:focus ~ .line:after {
      height: 2px;
    }
    &:focus ~ label,
    &:focus ~ label {
      top: -25px;
      font-size: 10px;
      color: $gid-blue;
    }
    &:valid ~ label {
      top: -25px;
      font-size: 10px;
      color: $gid-lightBlack;
    }
    &:focus:valid ~ label {
      color: $gid-blue;
    }
    &:focus ~ .line:after,
    &:focus ~ .line:after {
      width: 100%;
    }
    &:focus:valid ~ .line:after {
      width: 100%;
    }
  }
  & label {
    position: absolute;
    top: -5px;
    left: 0;
    color: $gid-black;
    transition: all 0.5s;
  }
  & .line {
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: -8px;
    background: $gid-black;
    left: 0;
    transition: all 0.3s;

    &:after {
      content: "";
      display: block;
      width: 0;
      background: $gid-blue;
      height: 1px;
    }
  }
  &:hover {
    & :valid ~ label {
      color: $gid-blue;
    }
    & .line:after {
      width: 100%;
    }
    & label:valid {
      color: $gid-blue;
    }
  }
  span.formError {
    position: absolute;
    color: $gid-error;
    font-size: 10px;
    top: calc(100% + 2px);
    left: 0;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $gid-grey inset !important;
    -webkit-box-shadow: 0 0 0 30px $gid-grey inset !important;
  }
}
