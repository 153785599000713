@import "../../../scss/main.scss";

#mvp-testimonial {
  background-color: $gid-white;
  grid-column: 1 / 13;
  margin-bottom: 240px;
  @include breakpoint(tablet) {
    margin-bottom: 180px;
    grid-column: 1 / 9;
  }
  @include breakpoint(mobile) {
    grid-column: 1 / 5;
  }
  .content {
    .testimonial {
      grid-column: 3 / 11;
      margin-bottom: 31px;
      position: relative;
      h2 {
        z-index: 2;
        grid-column: 1 / 13;
        @include breakpoint(tablet) {
          grid-column: 1 / 9;
        }
        @include breakpoint(mobile) {
          grid-column: 1 / 5;
        }
      }
      svg {
        position: absolute;
        z-index: 1;
        top: -85px;
        left: 0;
        @include breakpoint(mobile) {
          top: -60px;
          left: -50px;
        }
      }
      @include breakpoint(tablet) {
        margin-bottom: 18px;
        grid-column: 1 / 9;
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
      }
    }
    .signature {
      grid-column: 3 / 11;
      @include breakpoint(tablet) {
        grid-column: 1 / 9;
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
      }
    }
  }
}
