@import "../../../scss/main.scss";

#home {
  background-color: $gid-black;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: calc(65px + 5vw) 0 0;
  @include breakpoint(ultrawide) {
    padding-top: 10vh;
  }
  @include breakpoint(tablet) {
    padding-top: 50px;
    display: unset;
    height: unset;
    align-items: unset;
    .black {
      padding: 108px 0 60px 0;
    }
  }
  .hero {
    grid-column: 1 / 7;
    justify-content: center;
    h1 {
      margin-bottom: 52px;
      text-transform: uppercase;
      @include breakpoint(tablet) {
        margin-top: 108px;
      }
      @include breakpoint(mobile) {
        margin-top: 52px;
      }
    }
    p {
      @include breakpoint(mobile) {
        margin-bottom: 25px;
      }
    }
    @include breakpoint(tablet) {
      order: 2;
      grid-column: 1 / 7;
    }
    @include breakpoint(mobile) {
      order: 2;
      grid-column: 1 / 5;
      margin-right: 0px;
    }
  }
  .great-x {
    grid-column: 8 / 13;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(tablet) {
      order: 1;
      grid-column: 2 / 8;
      margin-left: 40px;
      margin-right: 40px;
    }
    @include breakpoint(mobile) {
      order: 1;
      grid-column: 1 / 5;
      margin-left: 0px;
      margin-right: 0px;
    }
    &__container {
      video {
        width: 100%;
      }
    }
  }

  .contact {
    display: flex;
    padding-bottom: 30px;
    align-items: flex-end;
    @include breakpoint(tablet) {
      position: static;
      padding-bottom: 0px;
      order: 3;
    }
    @include breakpoint(mobile) {
      order: 3;
      grid-column: 1 / 5;
    }
    &__container {
      align-items: flex-end;
      & > * + * {
        margin: 0 0 0 100px;
      }
      @include breakpoint(mobile) {
        display: none;
      }
      .phone,
      .email {
        align-items: center;
        & > * + * {
          margin: 0 0 0 20px;
        }
        svg {
          fill: $gid-white;
        }
      }
      a {
        text-decoration: underline;
        @include breakpoint(tablet) {
          width: auto;
        }
      }
    }
    &__btn {
      display: none;
      @include breakpoint(tablet) {
        display: none;
      }
      @include breakpoint(mobile) {
        display: unset;
        width: 100%;
        .btn {
          width: 100%;
        }
      }
    }
    aside {
      position: absolute;
      right: -70px;
      bottom: 95px;
      transform: rotate(-90deg);
      @include breakpoint(tablet) {
        bottom: 65px;
      }
      @include breakpoint(mobile) {
        display: none;
      }
      .aside__container {
        display: flex;
      }
      a {
        text-transform: uppercase;
        position: relative;
        img {
          position: absolute;
          top: -35px;
          left: 17px;
          transform: rotate(90deg);
        }
        span {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
}
