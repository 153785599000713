@import "../../scss/main.scss";

.chip {
  padding: 9px 16px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid $gid-black;
  background-color: $gid-grey;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s;
  margin: 0 16px 16px 0;
  @include flexCenter;
  &:hover,
  &:focus {
    box-shadow: 0 0 0 1px $gid-black;
  }
  &.disabled {
    pointer-events: none;
    color: $gid-semiBlack;
    border-color: $gid-semiBlack;
  }
  &.active {
    background-color: $gid-black;
    color: $gid-white;
    border-color: transparent;
  }
}
