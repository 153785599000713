@import "../../scss/main.scss";

#testview {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  main {
    width: 50vw;
    height: 50vh;
    section {
      p {
        writing-mode: vertical-lr;
        text-orientation: upright;
        height: 400px;
      }
    }
  }
}
