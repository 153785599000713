@import "../../../scss/main.scss";

#about {
  background-color: $gid-white;
  padding-bottom: 200px;
  padding-top: 120px;
  position: relative;

  @include breakpoint(tablet) {
    padding-bottom: 168px;
    padding-top: 130px;
  }
  @include breakpoint(mobile) {
    padding-bottom: 120px;
    padding-top: 19px;
  }
  .wrapper {
    position: unset;
  }
  main {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    grid-column: 1 / 6;
    justify-content: center;

    @include breakpoint(tablet) {
      align-items: center;
      grid-column: 3 / 7;
      margin: 100px -60px 0;
      order: 2;
    }
    @include breakpoint(mobile) {
      grid-column: 1 / 5;
      margin: 100px 0 0;
      order: 2;
    }
    h1 {
      margin-bottom: 72px;

      @include breakpoint(tablet) {
        text-align: center;
      }
    }
    h3 {
      margin: 0 0 13px;

      @include breakpoint(tablet) {
        margin: 72px 0 13px;
        text-align: center;
      }
    }
    p {
      @include breakpoint(tablet) {
        text-align: center;
      }
    }
    .social {
      margin-top: 42px;

      @include breakpoint(tablet) {
        padding-bottom: 39px;
        padding-top: 40px;
      }
      &__container {
        align-items: center;
        & > * + * {
          margin: 0 0 0 40px;
        }

        @include breakpoint(tablet) {
          align-items: center;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-end;
          & > * + * {
            margin: 20px 0 20px 0;
          }
        }
        @include breakpoint(mobile) {
          flex-wrap: nowrap;
        }
        &__links {
          & > * + * {
            margin: 0 0 0 24px;
          }

          a {
            height: 40px;

            & > svg {
              fill: $gid-black;
              height: 40px;
              width: 40px;
            }
          }
        }
      }
    }
  }
  aside {
    align-self: center;
    grid-column: 8/ 13;

    @include breakpoint(tablet) {
      align-self: center;
      grid-column: 3 / 7;
      margin-bottom: 100px;
      margin: 0 -60px;
      order: 1;
    }
    @include breakpoint(mobile) {
      grid-column: 1 / 5;
      margin: 0 0 0px;
      order: 1;
    }
    .img-container {
      width: inherit;

      img {
        width: 100%;
      }
    }
  }
}
