@import "../../../scss/main.scss";

#work {
  background-color: $gid-white;
  padding-top: 70px;
  padding-bottom: 240px;
  @include breakpoint(tablet) {
    padding-top: 185px;
    padding-bottom: 160px;
  }
  @include breakpoint(mobile) {
    padding-top: 100px;
    padding-bottom: 160px;
  }
  .wrapper {
    .title-container {
      grid-column: 1 / 2;
      margin-right: -20px;
      transform: rotate(180deg);
      align-items: center;
      white-space: nowrap;
      @include breakpoint(tablet) {
        position: unset;
        grid-column: 1 / 9;
        transform: unset;
        order: 1;
        margin-bottom: 0;
        white-space: normal;
        h1 {
          color: $gid-black;
          -webkit-text-stroke: unset;
          text-transform: uppercase;
        }
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
        transform: unset;
        order: 1;
      }
      h1 {
        writing-mode: vertical-rl;
        @include breakpoint(tablet) {
          writing-mode: unset;
        }
      }
    }
    .box-container {
      grid-column: 4 / 13;
      display: flex;
      flex-direction: column;
      & > * + * {
        margin: 20px 0 0px 0;
      }
      @include breakpoint(tablet) {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-column: 1 / 9;
        order: 2;
      }
      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
        grid-column: 1 / 5;
        order: 2;
      }
    }
  }
}
