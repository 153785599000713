.xshape {
  display: flex;
  justify-content: center;
  align-items: center;
  .clip-svg {
    position: relative;
    width: 100%;
    clip-path: url(#clip-path);
  }
  svg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
