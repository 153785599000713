@import "../../scss/main.scss";

.areaBox {
  position: relative;
  width: 100%;
  & textarea {
    background-color: $gid-grey;
    z-index: 1;
    width: 100%;
    display: block;
    max-width: 100%;
    resize: none;
    &:focus ~ .line:after {
      height: 2px;
    }
    &:focus ~ label,
    &:focus ~ label {
      top: -22px;
      font-size: 10px;
      color: $gid-blue;
    }
    &:valid ~ label {
      top: -22px;
      font-size: 10px;
      color: $gid-lightBlack;
    }
    &:focus:valid ~ label {
      color: $gid-blue;
    }
    &:focus ~ .line:after,
    &:focus ~ .line:after {
      width: 100%;
    }
    &:focus:valid ~ .line:after {
      width: 100%;
    }
  }
  & label {
    position: absolute;
    top: 0px;
    left: 0;
    color: $gid-black;
    transition: all 0.5s;
  }
  & .line {
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: -5px;
    background: $gid-black;
    left: 0;
    transition: all 0.3s;
    &.error {
      background: $gid-error;
    }
    &:after {
      content: "";
      display: block;
      width: 0;
      background: $gid-blue;
      height: 1px;
    }
  }
  & .counter {
    position: absolute;
    top: 6.5px;
    left: 0;
  }
  &:hover {
    & :valid ~ label {
      color: $gid-blue;
    }
    & .line:after {
      width: 100%;
    }
    & label:valid {
      color: $gid-blue;
    }
  }
}
