@import "../../scss/main.scss";

.btn {
  background-color: $gid-white;
  border-radius: 19px;
  border: 2px solid transparent;
  color: $gid-black;
  font-size: 1rem;
  font-weight: bold;
  height: 38px;
  line-height: 11px;
  padding: 11px 0 11px 0;
  text-transform: uppercase;
  transition: 0.4s;
  width: 197px;
  cursor: pointer;

  &.reverse {
    background-color: $gid-black;
    color: $gid-white;

    @include breakpoint(mobile) {
      width: 100%;
    }
  }
  &.white {
    background-color: $gid-white;
    border: 1px solid $gid-black;
    color: $gid-black;
    outline: none;
    font-weight: normal;
    width: fit-content;
    padding: 10px 22px;

    @include breakpoint(tablet) {
      display: none;
    }

    &:hover {
      background-color: $gid-black;
      border: 1px solid transparent;
      color: $gid-white;
      outline: none;
    }
    &:focus {
      background-color: $gid-white;
      border: 2px solid $gid-black;
      color: $gid-black;
    }
  }
  &:hover {
    background-color: $gid-black;
    border: 2px solid $gid-white;
    color: $gid-white;

    &.reverse {
      background-color: $gid-white;
      border: 2px solid $gid-black;
      color: $gid-black;
    }
  }
  &:focus {
    border: 2px solid $gid-black;
    box-shadow: 0 0 0 2px $gid-white;
    transition: 0.4s;

    &.reverse {
      background-color: $gid-black;
      border: 1px solid $gid-white;
      box-shadow: 0 0 0 2px $gid-black;
      color: $gid-white;
    }
  }
  &:disabled {
    background-color: $gid-semiWhite;
    outline: none;
    pointer-events: none;

    &.reverse {
      background-color: $gid-semiBlack;
      color: $gid-white;
    }
  }
  &.transparent {
    position: relative;
    background-color: transparent;
    border: 2px solid $gid-white;
    color: $gid-white;
    width: 100%;
    &:hover {
      background-color: $gid-white;
      color: #051419;
      border: 2px solid transparent;
    }
  }
}

.container {
  display: flex;
}
