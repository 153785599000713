@import "../../scss/main.scss";

.offer {
  cursor: default;
  height: auto;
  width: 100%;
  &__container {
    align-items: center;
    justify-content: center;
    position: relative;

    &__visible {
      align-items: center;
      height: 330px;
      justify-content: space-between;
      padding: 72px 21px 0;
      transition: all 0.6s ease;
      width: 100%;
      transition-delay: 0.2s;
      border-radius: 32px;

      box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.03),
        -6px -6px 12px 0px rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.03),
        -6px -6px 12px 0px rgba(255, 255, 255, 0.3);
      @include breakpoint(tablet) {
        align-items: flex-start;
        box-shadow: unset;
        height: unset;
        padding: 67px 0 0;
      }
      .image-container {
        align-items: center;
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
          margin: 30px 0;
          order: 2;
        }
        img {
          height: 150px;
          width: 150px;

          @include breakpoint(tablet) {
            height: 80px;
            width: 80px;
          }
        }
      }
      .text-container {
        color: transparent;
        margin: 0 0 51px 0;
        position: relative;
        width: 100%;

        @include breakpoint(tablet) {
          margin: 0 0 0px 0;
          order: 1;
        }
        h3 {
          left: 50%;
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          transition: all 0.6s ease;
          white-space: nowrap;
          transition-delay: 0.2s;

          @include breakpoint(tablet) {
            left: 0;
            position: unset;
            transform: unset;
          }
        }
      }
    }
    &__hidden {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      opacity: 0;
      padding: 48px 21px 0;
      transition: all 0.6s ease;
      transition-delay: 0.2s;
      height: 300px;
      @include breakpoint(tablet) {
        opacity: 1;
        padding: 0;
        height: unset;
      }
    }
  }
  @include breakpoint(mobile) {
    padding-bottom: 0;
  }
  &.hover {
    .offer__container__visible,
    .offer__container__hidden,
    h3 {
      transition-delay: 0.2s;
    }
    .offer__container__visible {
      box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.1),
        -6px -6px 12px 0px rgba(255, 255, 255, 1);
      -webkit-box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.1),
        -6px -6px 12px 0px rgba(255, 255, 255, 1);
      @include breakpoint(tablet) {
        box-shadow: unset;
      }
    }
    .offer__container__hidden {
      display: flex;
      flex-direction: column;
      opacity: 1;
    }
    h3 {
      left: 0px;
      transform: translateX(0);

      @include breakpoint(tablet) {
        transform: unset;
      }
    }
  }
}
