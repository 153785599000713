@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

h1 {
  font-family: lunatix;
  font-weight: bold;
  font-size: 7rem;
  line-height: 7rem;
  color: $gid-white;
  -webkit-text-stroke: 4px $gid-black;
  -webkit-font-smoothing: antialiased;
  &.small {
    font-size: 4.5rem;
    line-height: 4.5rem;
    @include breakpoint(mobile) {
      font-size: 3.375rem;
      line-height: 3.375rem;
    }
  }
  &.medium {
    font-size: 5.75rem;
    line-height: 5.75rem;
  }
  &.vbig {
    font-size: 15rem;
    line-height: 15rem;
    @include breakpoint(tablet) {
      font-size: 10rem;
      line-height: 10rem;
    }
    @include breakpoint(mobile) {
      font-size: 7rem;
      line-height: 7rem;
      color: $gid-black;
    }
  }
  &.helvetica {
    font-family: $fontFamily;
  }
  &.black {
    color: $gid-black;
    -webkit-text-stroke: unset;
  }
  &.upper {
    text-transform: uppercase;
  }
  &.career {
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 4.875rem;
    line-height: 4.625rem;
  }
  &.grey {
    color: $gid-grey;
  }
  &.white {
    color: $gid-white;
    -webkit-text-stroke: unset;
  }
  &.reverse {
    color: $gid-black;
    -webkit-text-stroke: 4px $gid-white;
  }
  &.narrow {
    -webkit-text-stroke: 2px $gid-black;
  }
  @include breakpoint(mobile) {
    font-size: 3.375rem;
    line-height: 3.375rem;
    -webkit-text-stroke: 3px $gid-black;

    &.reverse {
      -webkit-text-stroke: 2px $gid-white;
    }
  }
}
h2 {
  font-family: $fontFamily;
  font-weight: bold;
  font-size: 4.5rem;
  color: $gid-black;
  line-height: 5.25rem;
  letter-spacing: -20;
  -webkit-font-smoothing: antialiased;
  @include breakpoint(tablet) {
    font-size: 3rem;
    line-height: 4.5rem;
  }
  &.big {
    font-size: 7rem;
    line-height: 7rem;
    display: inline-block;
    @include breakpoint(tablet) {
      font-size: 7rem;
      line-height: 7rem;
    }
    @include breakpoint(mobile) {
      font-size: 3.375rem;
      line-height: 3.375rem;
    }
  }
  &.small {
    font-size: 3rem;
    line-height: 3rem;
    display: inline-block;
    font-weight: normal;
    @include breakpoint(mobile) {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
  &.career {
    text-transform: uppercase;
    font-size: 4.5rem;
    line-height: 4.5rem;
    @include breakpoint(mobile) {
      font-size: 2.625rem;
      line-height: 2.625rem;
    }
  }
  &.light {
    font-weight: 100;
  }
  &.mvp-testimonial {
    font-weight: 100;
    @include breakpoint(mobile) {
      font-size: 2.25rem;
      line-height: 2.25rem;
    }
  }
  &.reverse {
    color: $gid-white;
  }
}
h3 {
  font-family: $fontFamily;
  font-weight: bold;
  font-size: 1.5rem;
  color: $gid-black;
  line-height: 2rem;
  -webkit-font-smoothing: antialiased;
  &.reverse {
    color: $gid-white;
  }
  &.upper {
    text-transform: uppercase;
  }
  &.bold {
    font-weight: bold;
  }
  &.big {
    font-size: 2rem;
    line-height: 2rem;
    @include breakpoint(mobile) {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}

p,
span,
a,
label,
textarea,
input {
  font-family: $fontFamily;
  font-weight: normal;
  font-size: 1rem;
  color: $gid-black;
  line-height: 1.75rem;
  -webkit-font-smoothing: antialiased;
  @include breakpoint(mobile) {
    font-size: 0.875rem;
  }
  &.reverse {
    color: $gid-white;
  }
  &.small {
    font-size: 0.875rem;
  }
  &.vsmall {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  &.big {
    font-size: 1.25rem;
  }
  &.bold {
    font-weight: bold;
  }
  &.upper {
    text-transform: uppercase;
  }
  &.mvp-testimonial {
    font-weight: bold;
    font-size: 1.25rem;
    @include breakpoint(mobile) {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}
a:hover {
  cursor: pointer;
}

span.techBanner-title {
  text-align: center;
  font-family: $fontFamily;
  font-weight: bold;
  font-size: 7rem;
  line-height: 7rem;
  color: $gid-white;
  -webkit-text-stroke: unset;
  @include breakpoint(mobile) {
    font-size: 3.375rem;
    line-height: 3.375rem;
  }
}
