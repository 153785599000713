.grid {
  grid-template-columns: repeat(12, 1fr);
  grid-column: 1 / 13;
  display: grid;
  gap: 20px;
  margin: 0;
  @include breakpoint(tablet) {
    grid-template-columns: repeat(8, 1fr);
    grid-column: 1 / 9;
  }
  @include breakpoint(mobile) {
    grid-template-columns: repeat(4, 1fr);
    grid-column: 1 / 5;
  }
}
