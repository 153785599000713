@import "../../../scss/main.scss";

#mvp-chart {
  .chart {
    display: grid;
    gap: 20px;
    grid-column: 1 / 13;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 180px;
    text-align: center;
    @include breakpoint(tablet) {
      grid-column: 1 / 9;
      grid-template-columns: repeat(8, 1fr);
    }
    @include breakpoint(mobile) {
      grid-column: 1 / 5;
      grid-template-columns: repeat(4, 1fr);
    }
    .title {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 27px;
      grid-column: 3 / 11;

      h2 {
        font-size: 3rem;
        font-weight: normal;
        line-height: 3rem;

        @include breakpoint(mobile) {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
      }
      p {
        font-size: 1.25rem;

        @include breakpoint(mobile) {
          font-size: 1rem;
        }
      }
      @include breakpoint(tablet) {
        grid-column: 2 / 8;
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
      }
    }
    .chart-container {
      display: grid;
      gap: 20px;
      grid-column: 1 / 13;
      grid-template-columns: repeat(12, 1fr);
      margin-top: 120px;

      img {
        grid-column: 1 / 13;
        width: 100%;
      }
      @include breakpoint(tablet) {
        grid-column: 1 / 9;
        grid-template-columns: repeat(8, 1fr);
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
        grid-template-columns: repeat(4, 1fr);
      }
    }
    .footer {
      display: grid;
      gap: 20px;
      grid-column: 1 / 13;
      grid-template-columns: repeat(12, 1fr);

      @include breakpoint(tablet) {
        grid-column: 1 / 9;
        grid-template-columns: repeat(8, 1fr);
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
        grid-template-columns: repeat(4, 1fr);
      }
      .chart-description {
        display: grid;
        column-gap: 20px;
        row-gap: 80px;
        grid-column: 1 / 13;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @include breakpoint(tablet) {
          grid-column: 1 / 9;
          grid-template-columns: 1fr 1fr;
        }
        @include breakpoint(mobile) {
          grid-column: 1 / 5;
          grid-template-columns: 1fr;
        }
        .describe-container {
          text-align: start;
          margin-right: 80px;
          grid-column: 1fr;
          @include breakpoint(tablet) {
            margin-right: 0;
          }
          @include breakpoint(mobile) {
          }
          h1 {
            font-size: 32px;
          }
          p {
            font-size: 14px;
          }
        }
      }
      .btn-container {
        margin-top: 140px;
        grid-column: 5 / 9;
        @include breakpoint(tablet) {
          grid-column: 3 / 7;
        }
        @include breakpoint(mobile) {
          grid-column: 1 / 5;
        }
        .btn {
          width: 100%;
        }
      }
    }
  }
}
