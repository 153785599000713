@import "../../scss/main.scss";

.logo {
  z-index: 99;
  &.white > svg {
    fill: $gid-white;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
  }
  &.black > svg {
    fill: $gid-black;
    transition: all 0.5s ease;
  }
}
