* > .section-background {
  max-width: 1920px;
  margin: 0 auto;
  &.black {
    background-color: $gid-black;
  }
  &.white {
    background-color: $gid-white;
  }
  &.grey {
    background-color: $gid-grey;
  }
}

* > .wrapper {
  position: relative;
  margin: 0 $sideMargin 0;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 20px;
  @include breakpoint(tablet) {
    grid-template-columns: repeat(8, [col-start] 1fr);
  }
  @include breakpoint(mobile) {
    margin: 0 $sideMarginMobile 0;
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
  & > * {
    grid-column: col-start / span 12;
    @include breakpoint(tablet) {
      grid-column: col-start / span 8;
    }
    @include breakpoint(mobile) {
      grid-column: col-start / span 4;
    }
  }
}
