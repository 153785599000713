@import "../../../scss/main.scss";

#career {
  background-color: $gid-white;

  .wrapper {
    main:hover {
      h1 {
        opacity: 1;
      }
      h2 {
        opacity: 0.05;

        @include breakpoint(tablet) {
          opacity: 1;
        }
      }
      & + aside > .animation-container {
        transform: translate(-50%, -120%);

        .smile {
          opacity: 1;
        }
        .career-link {
          opacity: 1;
          transform: translate(-50%, -50px);

          .career {
            transform: translateX(150%);
          }
          .arrow {
            transform: translateX(-250%);
          }
        }
      }
    }
    padding-bottom: 200px;
    padding-top: 240px;

    @include breakpoint(mobile) {
      padding-bottom: 80px;
      padding-top: 100px;
    }
    main {
      cursor: default;
      grid-column: 1 / 5;
      margin-right: -40px;
      position: relative;

      h2,
      h1 {
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.4s ease;
      }
      h1 {
        opacity: 0.04;
        transition: opacity 0.4s ease;
      }
      h2.hidden {
        position: unset;
        visibility: hidden;
      }
      @include breakpoint(tablet) {
        grid-column: 1 / 9;
        margin-right: 0px;

        h1 {
          display: none;
        }
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 5;
      }
    }
    aside {
      align-items: center;
      grid-column: 8 / 12;
      position: relative;

      .animation-container {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.4s ease;
      }
      .x-container {
        gap: 60px;
        transition: transform 0.4s ease;
      }
      .smile {
        bottom: -113px;
        left: 50%;
        opacity: 0;
        position: absolute;
        transform: translateX(-50%);
        transition: all 0.4s ease;
        width: 290px;
      }
      .career-link {
        bottom: -245px;
        gap: 50px;
        left: 50%;
        opacity: 0;
        position: absolute;
        transform: translate(-50%, 0%);
        transition: all 0.4s ease;
        width: 100%;

        &__container {
          align-items: center;
          position: relative;

          .career,
          .arrow {
            position: absolute;
            transition: transform 0.4s ease;
          }
          .career {
            left: 0;
          }
          .arrow {
            right: 0;
          }
        }
      }
      @include breakpoint(tablet) {
        display: none;
      }
    }
  }
}
