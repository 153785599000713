@import "../../scss/main.scss";

#cookie {
  position: fixed;
  z-index: 100;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  @include breakpoint(tablet) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &.hidden {
    display: none;
  }
  main {
    margin: 0 $sideMargin;
    min-height: 50px;
    justify-content: flex-start;
    align-items: center;
    @include breakpoint(tablet) {
      margin: 0 $sideMarginMobile;
      flex-direction: column;
    }
    .cookie-container {
      align-items: center;
      margin: 21px 0;
      img {
        padding-right: 45px;
      }
      p {
        width: 80%;
        a {
          text-decoration: underline;
        }
      }
    }
    .btn {
      min-width: 197px;
    }
  }
}
