@import "../../scss/main.scss";

#footer {
  margin-top: 74px;
  margin-bottom: 58px;
  grid-column: 9 / 13;
  @include breakpoint(tablet) {
    order: 2;
    grid-column: 1 / 9;
  }
  @include breakpoint(mobile) {
    order: 2;
    grid-column: 1 / 5;
  }
  .testimonial {
    border-bottom: 1px solid rgba(3, 3, 3, 0.2);
    padding-bottom: 40px;
    grid-column: 1 / 5;
    p {
      width: 90%;
      margin: 28px 0 22px;
    }
    span {
      color: $gid-lightBlack;
      padding-right: 10px;
    }
  }
  .navigation {
    padding: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .nav {
      order: 1;
      grid-column: 1 / 3;
      ul {
        & > * + * {
          margin: 35px 0 0 0;
        }
      }
    }
    .regulations {
      order: 2;
      grid-column: 3 / 5;
      ul {
        & > * + * {
          margin: 35px 0 0 0;
        }
      }
    }
  }
  .contacts {
    grid-column: 1 / 5;
    @include breakpoint(tablet) {
      border-top: 1px solid rgba(3, 3, 3, 0.2);
    }
    @include breakpoint(mobile) {
    }
    .contact {
      padding: 23px 0;
      border-top: 1px solid rgba(3, 3, 3, 0.2);
      border-bottom: 1px solid rgba(3, 3, 3, 0.2);

      @include breakpoint(tablet) {
        border: none;
      }
      @include breakpoint(mobile) {
        border-bottom: 1px solid rgba(3, 3, 3, 0.2);
      }
      &__container {
        display: grid;
        gap: 20px;
        grid-column: 1 / 5;
        .phone,
        .email {
          align-items: center;
          & > * + * {
            margin: 0 0 0 20px;
          }
        }
        .phone {
          grid-column: 1 / 3;
          @include breakpoint(mobile) {
            grid-column: 1 / 4;
            order: 1;
          }
        }
        .email {
          grid-column: 3 / 5;
          @include breakpoint(mobile) {
            grid-column: 1 / 4;
            order: 2;
          }
        }
        svg {
          fill: $gid-black;
        }
        a {
          text-decoration: underline;
          @include breakpoint(tablet) {
            width: auto;
          }
        }
      }
    }
    .social {
      padding-top: 40px;
      padding-bottom: 68px;
      @include breakpoint(tablet) {
        padding: 0;
      }
      @include breakpoint(tablet) {
        padding-top: 40px;
        padding-bottom: 68px;
      }
      &__container {
        @include breakpoint(tablet) {
          justify-content: flex-end;
          flex-wrap: wrap;
        }
        @include breakpoint(mobile) {
          flex-wrap: nowrap;
        }
        &__links {
          & > * + * {
            margin: 0 0 0 24px;
          }
          a > svg {
            fill: $gid-black;
          }
        }
      }
    }
  }
  .copyrights {
    grid-column: 1 / 5;
    justify-content: space-between;
    @include breakpoint(mobile) {
      flex-direction: column;
      & > * + * {
        margin: 30px 0 0 0;
      }
    }
  }
}
