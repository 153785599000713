@import "../../scss/main.scss";
$checkboxSize: 16px;
/* The container */
.container {
  display: flex;
  position: relative;
  padding-left: $checkboxSize + 12px;
  // margin-bottom: 12px;
  align-items: center;
  align-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid $gid-black;
  opacity: 0.8;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  border: 2px solid $gid-blue;
  opacity: 1;
}

// on focus state
.container:focus input ~ .checkmark {
  border: 2px solid $gid-blue;
  opacity: 1;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $gid-blue;
  border: 2px solid transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: 2px solid transparent;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  top: 1px;
  left: 3px;
  width: 3px;
  height: 6px;
  border: solid $gid-grey;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container .checkmark:hover:after {
  top: 1px;
  left: 3px;
}
