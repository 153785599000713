* {
  margin: 0;
  text-decoration: none;
  border: none;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
