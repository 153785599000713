@import "../../../scss/main.scss";

#bannerTech {
  background-color: $gid-black;
  background-image: url(../../../img/techBannerColor.png);
  background-position: center 0px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  margin-bottom: 70px;
  @include breakpoint(tablet) {
    background-position: -800px 0px;
  }
  @include breakpoint(mobile) {
    background-position: -700px 0px;
  }
  .wrapper {
    height: 100vh;
    h1,
    h3,
    p {
      text-align: center;
    }
    main {
      display: grid;
      gap: 20px;
      grid-column: 1 / 13;
      grid-template-columns: repeat(12, 1fr);

      @include breakpoint(tablet) {
        grid-template-columns: repeat(8, 1fr);
      }
      @include breakpoint(mobile) {
        grid-template-columns: repeat(4, 1fr);
      }
      .flex {
        align-items: center;
        grid-column: 1 / 13;
        justify-content: center;
        .content {
          display: grid;
          gap: 20px;
          grid-column: 1 / 13;
          grid-template-columns: repeat(12, 1fr);
          order: 1;

          @include breakpoint(tablet) {
            grid-column: 2 / 8;
            grid-template-columns: repeat(8, 1fr);
            margin-top: 100px;
          }
          @include breakpoint(mobile) {
            grid-column: 1 / 5;
            grid-template-columns: repeat(4, 1fr);
          }
          h3 {
            grid-column: 3 / 11;
            margin-bottom: 28px;

            @include breakpoint(tablet) {
              grid-column: 2 / 8;
            }
            @include breakpoint(mobile) {
              grid-column: 1 / 5;
            }
          }
          .big-title {
            grid-column: 2 / 12;
            display: flex;
            justify-content: center;
            @include breakpoint(tablet) {
              grid-column: 1 / 9;
            }
            @include breakpoint(mobile) {
              grid-column: 1 / 5;
            }
          }
          p {
            grid-column: 4 / 10;
            margin-bottom: 60px;
            @include breakpoint(tablet) {
              grid-column: 3 / 7;
              margin: 0 -20px;
            }
            @include breakpoint(mobile) {
              grid-column: 1 / 5;
              margin: 0 0px;
            }
          }
        }
        .link-container {
          display: grid;
          gap: 20px;
          width: 100%;
          grid-template-columns: repeat(12, 1fr);
          margin-bottom: 52px;
          order: 2;

          @include breakpoint(tablet) {
            grid-column: 1 / 9;
            grid-template-columns: repeat(8, 1fr);
            margin-top: 50px;
          }
          @include breakpoint(mobile) {
            grid-column: 1 / 5;
            grid-template-columns: repeat(4, 1fr);
          }
          .contact-link {
            grid-column: 5 / 9;
            @include breakpoint(tablet) {
              grid-column: 3 / 7;
            }
            @include breakpoint(mobile) {
              grid-column: 1 / 5;
            }
          }
        }
      }
    }
  }
}
