@import "../../scss/main.scss";

.work-box {
  cursor: default;
  display: grid;
  grid-column: 4 / 13;
  grid-gap: 20px;
  grid-template-columns: repeat(9, 1fr);
  transition: all 0.6s ease-out;

  @include breakpoint(tablet) {
    display: flex;
    flex-direction: column;
    grid-column: unset;
    margin-top: 72px;
  }
  .box {
    border-radius: 32px;
    grid-column: 1 / 3;
    margin-right: -20px;
    padding-top: 100%;
    position: relative;
    transition: all 0.6s ease;
    width: 100%;
    z-index: 2;

    @include breakpoint(tablet) {
      order: 2;
      padding: 31px 0;
    }
    @include breakpoint(mobile) {
      margin-right: 0px;
    }
    img {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;

      @include breakpoint(tablet) {
        left: 0;
        position: unset;
        top: 0;
        transform: unset;
        width: 80px;
      }
    }
  }
  .title {
    align-items: center;
    grid-column: 3 / 5;
    justify-content: flex-start;
    margin-left: 10px;
    transition: transform 0.5s ease;
    white-space: pre-wrap;

    @include breakpoint(tablet) {
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 0px;
      order: 1;
      white-space: normal;
    }
  }
  .content {
    align-items: center;
    grid-column: 5 / 9;
    margin-left: 20px;
    opacity: 0;
    transition: opacity 0.6s ease;

    @include breakpoint(tablet) {
      margin-left: 0px;
      opacity: 1;
      order: 3;
    }
  }
  .hidden {
    visibility: hidden;
  }
  &.hover > .hidden {
    visibility: visible;
  }
  &.hover {
    & > .box,
    & > .title,
    & > .content {
      transition-delay: 0.2s;
    }
    & > .box {
      transform: translateX(108%);
      transition: transform 0.6s ease;

      @include breakpoint(tablet) {
        transform: unset;
        transition: unset;
      }
    }
    & > .title {
      transform: translateX(-110%);
      transition: transform 0.6s ease;

      @include breakpoint(tablet) {
        transform: unset;
        transition: unset;
      }
    }
    & > .content {
      opacity: 1;
      transition: opacity 0.6s ease;

      @include breakpoint(tablet) {
        transform: unset;
        transition: unset;
      }
    }
  }
}
