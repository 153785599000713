@import "../../../scss/main.scss";

#mvp-features {
  padding-top: 180px;
  .features-container {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 13;
    @include breakpoint(tablet) {
      grid-column: 1 / 9;
    }
    @include breakpoint(mobile) {
      grid-column: 1 / 5;
    }
    .feature {
      width: 100%;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(12, 1fr);
      margin-bottom: 280px;
      @include breakpoint(tablet) {
        grid-template-columns: repeat(8, 1fr);
        margin-bottom: 140px;
      }
      @include breakpoint(mobile) {
        grid-template-columns: repeat(4, 1fr);
      }
      main {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        grid-column: 1 / 6;
        justify-content: center;

        @include breakpoint(tablet) {
          align-items: center;
          grid-column: 3 / 7;
          margin: 100px -60px 0;
          order: 2;
        }
        @include breakpoint(mobile) {
          grid-column: 1 / 5;
          margin: 100px 0 0;
          order: 2;
        }
        h1 {
          margin-bottom: 108px;

          @include breakpoint(tablet) {
            text-align: center;
            margin-bottom: 60px;
          }
          @include breakpoint(mobile) {
            margin-bottom: 35px;
          }
        }
        h3 {
          margin: 0 0 13px;

          @include breakpoint(tablet) {
            margin: 72px 0 13px;
            text-align: center;
          }
          @include breakpoint(mobile) {
            margin: 0px 0 13px;
          }
        }
        p {
          @include breakpoint(tablet) {
            text-align: center;
          }
        }
      }
      aside {
        align-self: center;
        grid-column: 8/ 13;

        @include breakpoint(tablet) {
          align-self: center;
          grid-column: 3 / 7;
          margin-bottom: 100px;
          margin: 0 -60px;
          order: 1;
        }
        @include breakpoint(mobile) {
          grid-column: 1 / 5;
          margin: 0 0 0px;
          order: 1;
        }
        .img-container {
          width: inherit;

          img {
            width: 100%;
          }
        }
      }

      &.reverse {
        main {
          order: 2;
          grid-column: 8 /13;
          @include breakpoint(tablet) {
            align-items: center;
            grid-column: 3 / 7;
            margin: 100px -60px 0;
            order: 2;
          }
          @include breakpoint(mobile) {
            grid-column: 1 / 5;
            margin: 100px 0 0;
            order: 2;
          }
        }
        aside {
          order: 1;
          grid-column: 1 / 6;
          @include breakpoint(tablet) {
            align-self: center;
            grid-column: 3 / 7;
            margin-bottom: 100px;
            margin: 0 -60px;
            order: 1;
          }
          @include breakpoint(mobile) {
            grid-column: 1 / 5;
            margin: 0 0 0px;
            order: 1;
          }
        }
      }
    }
  }
}
