@import "../../scss/main.scss";

#contactForm {
  grid-column: 1 / 8;

  @include breakpoint(tablet) {
    grid-column: 1 / 9;
    order: 1;
  }
  @include breakpoint(mobile) {
    grid-column: 1 / 5;
    order: 1;
  }
}
