@import "../../../scss/main.scss";

#form {
  .box {
    background-color: $gid-grey;
    padding: 0 42px;
    margin-top: -114px;
    @include breakpoint(tablet) {
      padding: 0 20px;
    }
    h1 {
      padding-top: 67.5px;
      padding-bottom: 9.5px;
    }
    p {
      padding-bottom: 62px;
      text-transform: uppercase;
    }
    form {
      justify-content: flex-start;
      @include breakpoint(tablet) {
        min-height: unset;
      }
      @include breakpoint(mobile) {
      }
      .chips {
        margin-bottom: 92px;
        margin-top: 20px;
        flex-wrap: wrap;
      }
      .inputs {
        margin-bottom: 150px;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .checkbox-container {
        display: flex;
        align-items: center;
        position: relative;
        p {
          padding: 0;
          text-transform: none;
        }
        span,
        a {
          text-decoration: underline;
          cursor: pointer;
        }
        .rodo-popup {
          position: absolute;
          padding: 20px 0 20px 20px;
          top: -250px;
          left: 0;
          background-color: $gid-black;
          opacity: 1;
          z-index: 5;
          transition: all 0.4s ease;
          justify-content: space-between;
          @include breakpoint(mobile) {
            top: -300px;
          }
          &.hidden {
            opacity: 0;
            z-index: -1;
            transition: all 0.4s ease;
          }
          p {
            width: 90%;
          }
          .close-btn {
            z-index: 6;
            margin: 0 20px;
            content: "";
            width: 20px;
            height: 20px;
            position: relative;
            cursor: pointer;
            &:before,
            &:after {
              position: absolute;
              content: "";
              width: 25px;
              height: 3px;
              background-color: $gid-white;
            }
            &::before {
              transform: rotate(-45deg) translate(-8px, 4px);
            }
            &::after {
              transform: rotate(45deg) translate(4px, 8px);
            }
          }
        }
      }
      .btn-container {
        padding-top: 20px;
        padding-bottom: 41px;
        @include breakpoint(mobile) {
          padding-top: 20px;
        }
      }
    }
  }
}
