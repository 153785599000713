@mixin breakpoint($point) {
  @if $point == ultrawide {
    @media (min-width: 120rem) {
      @content;
    }
  }
  @if $point == tablet {
    @media (max-width: 75rem) {
      @content;
    }
  }
  @if $point == smallTablet {
    @media (max-width: 50rem) {
      @content;
    }
  }
  @if $point == mobile {
    @media (max-width: 37.5rem) {
      @content;
    }
  }
}
