@import "../../../scss/main.scss";

#thankUPage {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 50px;
  .box {
    width: 100%;
    align-items: center;
    border-top-right-radius: 32px;
    border-bottom-left-radius: 32px;
    @include breakpoint(tablet) {
      min-height: unset;
    }
    &__content {
      padding: 0 100px;
      overflow: hidden;
      img {
        margin-top: -244px;
        margin-bottom: 80px;
        @include breakpoint(tablet) {
          margin-top: -100px;
        }
      }
      p {
        margin: 50px 0;
        text-transform: uppercase;
      }
      .contact {
        padding-top: 43px;
        padding-bottom: 43px;
        @include breakpoint(tablet) {
          border: none;
        }
        @include breakpoint(mobile) {
          width: 100%;
          border-bottom: 1px solid rgba(3, 3, 3, 0.2);
        }
        &__container {
          @include breakpoint(tablet) {
            row-gap: 20px;
            column-gap: 61px;
            flex-wrap: wrap;
          }
          @include breakpoint(mobile) {
            flex-wrap: nowrap;
            flex-direction: column;
          }
          a {
            position: relative;
            padding-left: 52px;
            text-decoration: underline;
            width: 50%;
            @include breakpoint(tablet) {
              width: auto;
            }
          }
          a[type="phone"]:before {
            width: 100%;
            content: url(../../../img/icons/phone-ico-dark.svg);
            position: absolute;
            left: 0px;
            top: 3px;
          }
          a[type="email"]:before {
            width: 100%;
            content: url(../../../img/icons/email-ico-dark.svg);
            position: absolute;
            left: 0px;
            top: 3px;
          }
        }
      }
    }
  }
}
