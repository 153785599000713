@import "../../scss/main.scss";
#header {
  width: 100%;
  z-index: 9;
  height: fit-content; // disable for test, don't delete
  position: fixed; //change from sticky for test , don't delete
  top: 0;
  transition: transform 250ms ease-in-out;
  &.black {
    background-color: $gid-black;
    & nav {
      display: flex;
      align-items: center;
      & > * + * {
        margin: 0 0 0 42px;
      }
      .menu-options {
        display: flex;
        align-items: center;
        & > * + * {
          margin: 0 0 0 66px;
        }
        a {
          color: $gid-white;
        }
        @include breakpoint(tablet) {
          padding-left: $sideMargin;
          flex-direction: column;
          align-items: flex-start;
          width: 100vw;
          height: 320px;
          position: absolute;
          top: -320px;
          left: -$sideMargin;
          transition: all 0.5s ease;
          background-color: $gid-white;
          padding-top: 96px;
          z-index: 10;
          & > * + * {
            margin: 22px 0 0;
          }
          a {
            transition: all 0.5s ease;
          }
          &.active {
            top: 0px;
            left: -$sideMargin;
            a {
              color: $gid-black;
              font-size: 20px;
            }
          }
        }
        @include breakpoint(mobile) {
          padding-left: $sideMarginMobile;
          left: -$sideMarginMobile;
          &.active {
            top: 0px;
            left: -$sideMarginMobile;
          }
        }
      }
      .btn {
        @include breakpoint(mobile) {
          display: none;
        }
      }
    }
    & .burger-menu {
      display: none;
      @include breakpoint(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 36px;
        height: 36px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        z-index: 99;
        &__icon::before,
        &__icon::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          width: 36px;
          height: 6px;
          background-color: $gid-white;
          transition: all 0.5s ease-in-out;
        }
        &__icon::before {
          transform: translateY(6px);
        }
        &__icon::after {
          transform: translateY(-10px);
        }
        &.open .burger-menu__icon::before {
          background-color: $gid-black;
          width: 45px;
          transform: translate(-5px, -3px) rotate(45deg);
        }
        &.open .burger-menu__icon::after {
          background-color: $gid-black;
          width: 45px;
          transform: translate(-5px, -3px) rotate(-45deg);
        }
      }
    }
  }
  &.white {
    background-color: $gid-white;
    & nav {
      display: flex;
      align-items: center;
      & > * + * {
        margin: 0 0 0 42px;
      }
      .menu-options {
        display: flex;
        align-items: center;
        & > * + * {
          margin: 0 0 0 66px;
        }
        a {
          color: $gid-black;
        }
        @include breakpoint(tablet) {
          padding-left: $sideMargin;
          flex-direction: column;
          align-items: flex-start;
          width: 100vw;
          height: 320px;
          position: absolute;
          top: -320px;
          left: -$sideMargin;
          transition: all 0.5s ease;
          background-color: $gid-white;
          padding-top: 96px;
          z-index: 10;
          & > * + * {
            margin: 22px 0 0;
          }
          a {
            transition: all 0.5s ease;
          }
          &.active {
            top: 0px;
            left: -$sideMargin;
            a {
              color: $gid-black;
              font-size: 20px;
            }
          }
        }
        @include breakpoint(mobile) {
          padding-left: $sideMarginMobile;
          left: -$sideMarginMobile;
          &.active {
            top: 0px;
            left: -$sideMarginMobile;
          }
        }
      }
      .btn-redirecr {
        @include breakpoint(mobile) {
          display: none;
        }
      }
    }
    & .burger-menu {
      display: none;
      @include breakpoint(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 36px;
        height: 36px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        z-index: 99;
        &__icon::before,
        &__icon::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          width: 36px;
          height: 6px;
          background-color: $gid-black;
          transition: all 0.5s ease-in-out;
        }
        &__icon::before {
          transform: translateY(6px);
        }
        &__icon::after {
          transform: translateY(-10px);
        }
        &.open .burger-menu__icon::before {
          background-color: $gid-black;
          width: 45px;
          transform: translate(-5px, -3px) rotate(45deg);
        }
        &.open .burger-menu__icon::after {
          background-color: $gid-black;
          width: 45px;
          transform: translate(-5px, -3px) rotate(-45deg);
        }
      }
    }
  }
  &.nav-bar--hidden {
    transform: translateY(-100%);
  }
  .nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 0;
    & > a {
      z-index: 99;
      line-height: 0;
      font-size: 0;
    }
  }
}
