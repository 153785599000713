@import "../../../scss/main.scss";

#services {
  background-color: $gid-white;
  .wrapper {
    padding-top: 65px;
    margin-bottom: 120px;
    h1 {
      grid-column: 1 / 13;
      @include breakpoint(tablet) {
        order: 1;
        grid-column: 1 / 9;
        padding-bottom: 0px;
      }
      @include breakpoint(mobile) {
        order: 1;
        grid-column: 1 / 5;
      }
    }
    h3.subtitle {
      order: 2;
      padding-bottom: 72px;
      grid-column: 1 / 5;
      @include breakpoint(tablet) {
        display: none;
      }
    }
    .offers {
      grid-column: 1 / 13;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      order: 3;

      @include breakpoint(tablet) {
        order: 3;
        grid-template-columns: 1fr 1fr;
        grid-column: 1 / 9;
      }
      @include breakpoint(mobile) {
        order: 3;
        grid-template-columns: 1fr;
        grid-column: 1 / 5;
      }
    }
    a.hidden {
      display: none;
      @include breakpoint(mobile) {
        display: block;
        order: 4;
        grid-column: 1 / 5;
        margin-top: 87px;
        .btn {
          width: 100%;
        }
      }
    }
  }
}
